import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['buildingAgeInput', 'buildingAgeUnknownCheckbox']

  connect() {
    const targetInput = this.buildingAgeInputTarget
    const targetCheckbox = this.buildingAgeUnknownCheckboxTarget
    if (targetCheckbox.dataset.buildingAgeUnknown === 'true') {
      targetInput.setAttribute('disabled', true)
      targetInput.value = ''
      targetCheckbox.checked = true
    }
  }

  clickUnknown() {
    const targetInput = this.buildingAgeInputTarget
    const isChecked = this.buildingAgeUnknownCheckboxTarget.checked
    if (isChecked) {
      targetInput.setAttribute('disabled', true)
      targetInput.value = ''
    } else {
      targetInput.removeAttribute('disabled')
    }
  }
}
