import { Controller } from 'stimulus'

export default class extends Controller {
  statusChanged(e) {
    if (e.currentTarget.value == 'delete') {
      e.currentTarget.nextElementSibling.dataset.confirm =
        '本当に物件を一括削除してもよろしいですか？'
    } else {
      e.currentTarget.nextElementSibling.dataset.confirm = ''
    }
  }
}
