import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'currentPassword',
    'visibleCurrentPassword',
    'invisibleCurrentPassword',
    'password',
    'visiblePassword',
    'invisiblePassword',
    'confirmation',
    'visibleConfirmation',
    'invisibleConfirmation'
  ]

  showCurrentPassword() {
    this.currentPasswordTarget.type = 'text'
    this.visibleCurrentPasswordTarget.classList.add('hidden')
    this.invisibleCurrentPasswordTarget.classList.remove('hidden')
  }

  hideCurrentPassword() {
    this.currentPasswordTarget.type = 'password'
    this.visibleCurrentPasswordTarget.classList.remove('hidden')
    this.invisibleCurrentPasswordTarget.classList.add('hidden')
  }

  showPassword() {
    this.passwordTarget.type = 'text'
    this.visiblePasswordTarget.classList.add('hidden')
    this.invisiblePasswordTarget.classList.remove('hidden')
  }

  hidePassword() {
    this.passwordTarget.type = 'password'
    this.visiblePasswordTarget.classList.remove('hidden')
    this.invisiblePasswordTarget.classList.add('hidden')
  }

  showConfirmation() {
    this.confirmationTarget.type = 'text'
    this.visibleConfirmationTarget.classList.add('hidden')
    this.invisibleConfirmationTarget.classList.remove('hidden')
  }

  hideConfirmation() {
    this.confirmationTarget.type = 'password'
    this.visibleConfirmationTarget.classList.remove('hidden')
    this.invisibleConfirmationTarget.classList.add('hidden')
  }
}
