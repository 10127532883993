import { Controller } from 'stimulus'

/*
Ref: https://github.com/yubinbango/yubinbango
YubinBangoライブラリを参考に必要な処理のみStimulusに実装しています
郵便番号を入力（ハイフン有り無し両方可）すると、住所フィールドに住所が自動で入力されます
具体的な使い方は↓を参照してください

<%= form_with model: model, data: { controller: 'postal-code' } do |f| %>
  <%= f.text_field :postal_code, maxlength: 8, data: { action: 'input->postal-code#setAddress', 'postal-code-target': 'postalCode'} %>
  <%= f.collection_select :prefecture_code, JpPrefecture::Prefecture.all, :code, :name, { include_blank: true }, { 'data-postal-code-target': 'prefectureCode' } %>
  <%= f.text_field :city, 'data-postal-code-target': 'city' %>
  <%= f.text_field :street, 'data-postal-code-target': 'street' %>
  <%= f.text_field :other_address, 'data-postal-code-target': 'otherAddress' %>
<% end %>
*/

let YUBINBANGO_DATA_CACHE = []

export default class extends Controller {
  YUBINBANGO_DATA_URL = 'https://yubinbango.github.io/yubinbango-data/data'
  static targets = ['postalCode', 'prefectureCode', 'city', 'street', 'otherAddress']

  setAddress() {
    const inputPostalCode = this.postalCodeTarget.value
    if (inputPostalCode) {
      // 全角の数字を半角に変換
      const hankakuPostalCode = inputPostalCode.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
      // ハイフンが入っていても数字のみの抽出
      const intPostalCode = hankakuPostalCode.match(/\d/g).join('')
      // 7桁の数字の時のみ作動
      if (intPostalCode.length === 7) {
        this.getAddress(intPostalCode)
      } else {
        this.setAddressFields()
      }
    }
  }

  getAddress(intPostalCode) {
    const postalCodeHead = intPostalCode.substr(0, 3)
    // 郵便番号上位3桁でキャッシュデータを確認
    if (
      postalCodeHead in YUBINBANGO_DATA_CACHE &&
      intPostalCode in YUBINBANGO_DATA_CACHE[postalCodeHead]
    ) {
      return this.selectAddress(YUBINBANGO_DATA_CACHE[postalCodeHead][intPostalCode])
    } else {
      this.getYubinbangoData(`${this.YUBINBANGO_DATA_URL}/${postalCodeHead}.js`, (data) => {
        YUBINBANGO_DATA_CACHE[postalCodeHead] = data
        return this.selectAddress(data[intPostalCode])
      })
    }
  }

  setAddressFields(prefectureCode = '', city = '', street = '', otherAddress = '') {
    this.prefectureCodeTarget.value = prefectureCode
    this.cityTarget.value = city
    this.streetTarget.value = street
    this.otherAddressTarget.value = otherAddress
  }

  selectAddress(addr) {
    if (addr && addr[0] && addr[1]) {
      return this.setAddressFields(addr[0], addr[1], addr[2], addr[3])
    } else {
      return this.setAddressFields()
    }
  }

  getYubinbangoData(url, fn) {
    window['$yubin'] = (data) => fn(data)
    const scriptTag = document.createElement('script')
    scriptTag.setAttribute('type', 'text/javascript')
    scriptTag.setAttribute('charset', 'UTF-8')
    scriptTag.setAttribute('src', url)
    document.head.appendChild(scriptTag)
  }
}
