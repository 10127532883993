import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'submit']

  onChange() {
    if (this.checkboxTarget.checked) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
