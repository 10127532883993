import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['label', 'checkBox']

  get isChecked() {
    return this.checkBoxTargets[0].checked && this.checkBoxTargets[1].checked
  }

  connect() {
    this.clearMismatchCheck()
    this.updateLabelClass()
  }

  updateLabelClass() {
    if (this.isChecked) {
      this.labelTarget.classList.add('bg-gray-400')
    } else {
      this.labelTarget.classList.remove('bg-gray-400')
    }
  }

  clearMismatchCheck() {
    if (this.checkBoxTargets[0].checked !== this.checkBoxTargets[1].checked) {
      this.checkBoxTargets.forEach((t) => (t.checked = false))
    }
  }

  checked() {
    this.checkBoxTargets[0].checked
  }

  toggleLinkedCheckBoxes() {
    const isChecked = this.isChecked
    this.checkBoxTargets.forEach((t) => (t.checked = !isChecked))
    this.updateLabelClass()
  }
}
