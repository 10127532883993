import '@fortawesome/fontawesome-free/js/all'
import * as ActiveStorage from '@rails/activestorage'
import Rails from '@rails/ujs'
import 'controllers' // Stimulus
import '../stylesheets/application'

const images = require.context('../images/', true)

Rails.start()
ActiveStorage.start()
