import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'links', 'propertyImage', 'addAssociationButton']

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields'
    this.check_property_image_count()
  }

  add_association(event) {
    event.preventDefault()

    let content = this.templateTarget.innerHTML.replace(/NEW/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
    this.check_property_image_count()
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.wrapperClass)

    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
      this.check_property_image_count()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
      this.check_property_image_count()
    }
  }

  check_property_image_count() {
    this.propertyImageTargets.length >= 5
      ? this.addAssociationButtonTarget.setAttribute('disabled', true)
      : this.addAssociationButtonTarget.removeAttribute('disabled')
  }
}
