import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['email', 'confirmationEmail', 'agreeCheckbox', 'submitButton']

  connect() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.style.backgroundColor = '#cccccc'
  }

  checkAgreeCheckbox() {
    const checkedAgreeCheckbox = this.agreeCheckboxTarget.checked
    const submitButton = this.submitButtonTarget
    submitButton.disabled = !checkedAgreeCheckbox
    submitButton.style.backgroundColor = checkedAgreeCheckbox ? '#e38d17' : '#cccccc'
  }
}
