import { Controller } from 'stimulus'

const activeButtonClassNames = ['border', 'border-black']

export default class extends Controller {
  static targets = [
    'searchModal',
    'sortModal',
    'showSearchButton',
    'showSortButton',
    'searchSortModal',
    'menuTab',
    'areaSearchTab',
    'areaSearchTabButton',
    'menuTabSp',
    'menuIcon'
  ]

  toggleSearchModal() {
    const targetModal = this.searchModalTarget
    const otherModals = [this.sortModalTarget]
    const targetButton = this.showSearchButtonTarget
    const otherButtons = [this.showSortButtonTarget]
    this.toggleModal(targetModal, otherModals)
    this.toggleActiveStyle(targetButton, otherButtons)
  }

  toggleSortModal() {
    const targetModal = this.sortModalTarget
    const otherModals = [this.searchModalTarget]
    const targetButton = this.showSortButtonTarget
    const otherButtons = [this.showSearchButtonTarget]
    this.toggleModal(targetModal, otherModals)
    this.toggleActiveStyle(targetButton, otherButtons)
  }

  toggleAreaSearch() {
    const targetTab = this.areaSearchTabTarget
    const targetTabButtons = this.areaSearchTabButtonTargets
    const otherTabs = []
    const otherTabButtons = []
    this.toggleTab(targetTab, targetTabButtons, otherTabs, otherTabButtons)
  }

  toggleSearchSortModal() {
    const targetModal = this.searchSortModalTarget
    if (targetModal.classList.contains('hidden')) {
      targetModal.classList.remove('hidden')
    } else {
      targetModal.classList.add('hidden')
    }
  }

  toggleModal(target, others) {
    const targetMenuTab = this.menuTabTarget
    if (target.classList.contains('hidden')) {
      target.classList.remove('hidden')
      targetMenuTab.classList.remove('hidden')
      others.forEach((other) => {
        other.classList.add('hidden')
      })
    } else {
      target.classList.add('hidden')
      targetMenuTab.classList.add('hidden')
    }
  }

  toggleTab(targetTab, targetTabButtons, otherTabs, otherTabButtons) {
    targetTab.classList.remove('hidden')
    targetTabButtons.forEach((button) => {
      button.classList.add('border-b')
    })
    otherTabs.forEach((tab) => {
      tab.classList.add('hidden')
    })
    otherTabButtons.forEach((button) => {
      button.classList.remove('border-b')
    })
  }

  toggleActiveStyle(target, others) {
    if (target.classList.contains(...activeButtonClassNames)) {
      target.classList.remove(...activeButtonClassNames)
    } else {
      target.classList.add(...activeButtonClassNames)
      others.forEach((other) => {
        other.classList.remove(...activeButtonClassNames)
      })
    }
  }

  closeAllModal() {
    const modals = [this.searchModalTarget, this.sortModalTarget]
    const buttons = [this.showSearchButtonTarget, this.showSortButtonTarget]
    const targetMenuTab = this.menuTabTarget

    modals.forEach((modal) => {
      modal.classList.add('hidden')
    })

    buttons.forEach((button) => {
      button.classList.remove(...activeButtonClassNames)
    })

    targetMenuTab.classList.add('hidden')
  }

  toggleModalSp() {
    const targetModal = this.searchModalTarget

    if (targetModal.classList.contains('slideUp')) {
      targetModal.classList.remove('slideUp')
      targetModal.classList.add('slideDown')
    } else {
      targetModal.classList.remove('hidden')
      targetModal.classList.add('slideUp')
      targetModal.classList.remove('slideDown')
    }
  }
}
